<template>
    <!-- BEGIN FOOTER -->
    <div class="footer-wrapper">
        <div class="footer-section f-section-1">
            <p class="">Copyright © 2024, <a target="_blank" href="https://skyvix.com.br">SOSTecnologia</a>, Todos os direitos
                reservados.</p>
        </div>
    </div>
    <!-- END FOOTER -->
</template>
