<template>
    <div class="layout-px-spacing">
        <teleport to="#breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>
        </teleport>

        <div class="row layout-spacing">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 layout-top-spacing">
                <div class="bio layout-spacing">
                    <div class="panel">
                        <div class="panel-body">
                            <h3 class="">Início</h3>
                            <p>
                               Seja bem vindo {{ nome_cliente }} ao portal do cliente.
                            </p>

                            <p>
                                Aqui Você poderá consultar todas as informações do seu plano e solicitar suporte para seu produto.
                            </p>

                            <div class="bio-skill-box">
                                <div class="row">
                                    <div class="col-12 col-xl-6 col-lg-12 mb-xl-5 mb-5">
                                        <router-link to="/cobrancas" class="d-flex b-skills" style="cursor: pointer;">
                                            <div></div>
                                            <div class="">
                                                <h5>Cobranças</h5>
                                                <p>Nessa área você poderá consultar todas as informações financeiras</p>
                                            </div>
                                        </router-link>
                                    </div>

                                    <div class="col-12 col-xl-6 col-lg-12 mb-xl-0 mb-5">
                                        <router-link to="/suporte" class="d-flex b-skills" style="cursor: pointer;">
                                            <div></div>
                                            <div class="">
                                                <h5>Suporte</h5>
                                                <p>Área dedicada ao suporte e solução de problemas do seu produto</p>
                                            </div>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import '@/assets/sass/scrollspyNav.scss';
    import '@/assets/sass/users/user-profile.scss';

    import { useMeta } from '@/composables/use-meta';
    useMeta({ title: 'Início' });

    const nome_cliente = localStorage.getItem('cliente')
</script>
